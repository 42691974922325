export const colors: {
  [maincolor: string]: {
    sub: { [subcolor: string]: string };
  };
} = {
  white: {
    sub: {
      white: '#fffefc',
      pearl: '#fbfcf7',
      alabaster: '#fefaf1',
      snow: '#f5fefd',
      ivory: '#fdf6e4',
      cream: '#fffada',
      'egg shell': '#fff9e3',
      cotton: '#fbfcf7',
      chiffon: '#fafaf2',
      salt: '#f7efed',
      lace: '#faf3eb',
      coconut: '#fff1e6',
      linen: '#f2ead4',
      bone: '#e7dfcc',
      daisy: '#ffffff',
      powder: '#fbfcf7',
      frost: '#ecfcfc',
      porcelain: '#fffefc',
      parchment: '#fbf5df',
      rice: '#faf5ef',
    },
  },
  tan: {
    sub: {
      tan: '#e7dbad',
      beige: '#ecdd9a',
      macaroon: '#f8e075',
      'hazel wood': '#c9bb8d',
      granola: '#d7b759',
      oat: '#dec98a',
      'egg nog': '#fae29c',
      fawn: '#c7a951',
      'sugar cookie': '#f3ecad',
      sand: '#d7b963',
      sepia: '#e3b778',
      latte: '#e9c27b',
      oyster: '#dcd8a0',
      biscotti: '#e3c564',
      parmesan: '#fde991',
      hazelnut: '#bea55c',
      sandcastle: '#dbc27b',
      buttermilk: '#fdefb2',
      'sand dollar': '#ece9b9',
      shortbread: '#fbe78f',
    },
  },
  yellow: {
    sub: {
      yellow: '#fde64b',
      canary: '#f9c804',
      gold: '#f9a604',
      daffodil: '#fdee87',
      flaxen: '#d7b759',
      butter: '#fee227',
      lemon: '#effd5f',
      mustard: '#e8b928',
      corn: '#e4cd06',
      medallion: '#e3b104',
      dandelion: '#fdce2a',
      fire: '#fda40e',
      bumblebee: '#fde207',
      banana: '#fcf4a3',
      butterscotch: '#fabd02',
      dijon: '#c29201',
      honey: '#ffc308',
      blonde: '#feeb74',
      pineapple: '#fee227',
      'tuscan sun': '#fcd12a',
    },
  },
  orange: {
    sub: {
      orange: '#ed7014',
      tangerine: '#f88229',
      marigold: '#fdae1e',
      cider: '#b56728',
      rust: '#8c3f04',
      ginger: '#bc5602',
      tiger: '#fc6a04',
      fire: '#dd561c',
      bronze: '#b2560f',
      cantaloupe: '#fca172',
      apricot: '#ed820f',
      clay: '#7e400b',
      honey: '#ec9604',
      carrot: '#ed7117',
      squash: '#c95b09',
      spice: '#793903',
      marmalade: '#d25f03',
      amber: '#883100',
      sandstone: '#d77029',
      yam: '#cd5800',
    },
  },
  red: {
    sub: {
      red: '#d0312d',
      cherry: '#990f03',
      rose: '#e3252c',
      jam: '#f50e0b',
      merlot: '#54201b',
      garnet: '#5f0b04',
      crimson: '#b80f0b',
      ruby: '#8f0503',
      scarlet: '#900d09',
      burgundy: '#4c0805',
      brick: '#7e2811',
      apple: '#a91b0e',
      mahogany: '#420c09',
      blood: '#710d04',
      sangria: '#5e1a14',
      berry: '#791811',
      currant: '#670c07',
      blush: '#bc5349',
      candy: '#d21503',
      lipstick: '#9c1003',
    },
  },
  pink: {
    sub: {
      pink: '#f69acd',
      rose: '#fc94ad',
      fuchsia: '#fc46aa',
      punch: '#f15178',
      blush: '#fdc5e6',
      watermelon: '#fe7f9c',
      flamingo: '#fda4b8',
      rouge: '#f26b8b',
      salmon: '#fdab9e',
      coral: '#fe7c68',
      peach: '#fb9383',
      strawberry: '#fc4c4d',
      rosewood: '#a04242',
      lemonade: '#fbbbcb',
      taffy: '#fa86c6',
      bubblegum: '#fd5ca8',
      'ballet slipper': '#f69abf',
      crepe: '#f1b9c6',
      magenta: '#e11483',
      'hot pink': '#ff1595',
    },
  },
  purple: {
    sub: {
      purple: '#a32dc5',
      mauve: '#794a88',
      violet: '#710193',
      boysenberry: '#620436',
      lavender: '#e39ff6',
      plum: '#601a36',
      magenta: '#a10559',
      lilac: '#b65fcd',
      grape: '#653047',
      periwinkle: '#bd93d3',
      sangria: '#4d0e29',
      eggplant: '#311433',
      jam: '#65052d',
      iris: '#9766c6',
      heather: '#9b7cb8',
      amethyst: '#a45ee5',
      raisin: '#290917',
      orchid: '#af68ee',
      mulberry: '#4b0120',
      wine: '#2c051a',
    },
  },
  blue: {
    sub: {
      blue: '#3a43ba',
      slate: '#747a87',
      sky: '#62c5da',
      navy: '#0c1071',
      indigo: '#281f5d',
      cobalt: '#1338bd',
      teal: '#47aaad',
      ocean: '#016063',
      peacock: '#002e36',
      azure: '#1620a5',
      cerulean: '#0892c2',
      lapis: '#2833c2',
      spruce: '#2d3e4c',
      stone: '#58788d',
      aegean: '#1e446e',
      berry: '#251570',
      denim: '#151f3d',
      admiral: '#061094',
      sapphire: '#52b1c0',
      arctic: '#82edfd',
    },
  },
  green: {
    sub: {
      green: '#3bb143',
      chartreuse: '#b0fd38',
      juniper: '#3a5311',
      sage: '#718c68',
      lime: '#adf359',
      fern: '#5cbc63',
      olive: '#97bf63',
      emerald: '#028810',
      pear: '#93b62f',
      moss: '#466c1f',
      shamrock: '#03ab12',
      seafoam: '#3bec97',
      pine: '#244f1e',
      parakeet: '#06c04b',
      mint: '#97edc3',
      seaweed: '#364a21',
      pickle: '#587d35',
      pistachio: '#b3d3c2',
      basil: '#32612d',
      crocodile: '#5f7d3c',
    },
  },
  brown: {
    sub: {
      brown: '#231709',
      coffee: '#4a381c',
      mocha: '#3c290e',
      peanut: '#795c34',
      carob: '#36260f',
      hickory: '#371d0f',
      wood: '#3f301e',
      pecan: '#492612',
      walnut: '#422711',
      caramel: '#65350e',
      gingerbread: '#5c2c04',
      syrup: '#462101',
      chocolate: '#2c1503',
      tortilla: '#9a7b4e',
      umber: '#362415',
      tawny: '#7d481d',
      brunette: '#3b1e08',
      cinnamon: '#622b0e',
      penny: '#522a15',
      cedar: '#493828',
    },
  },
  grey: {
    sub: {
      grey: '#6c626c',
      shadow: '#373737',
      graphite: '#574d5a',
      iron: '#322d31',
      pewter: '#6a687f',
      cloud: '#c6c6d0',
      silver: '#adadc7',
      smoke: '#59515d',
      slate: '#3f3d53',
      anchor: '#42424b',
      ash: '#554c4c',
      porpoise: '#4d4c5c',
      dove: '#7c6e7f',
      fog: '#645964',
      flint: '#7e7d9c',
      charcoal: '#232024',
      pebble: '#333333',
      lead: '#403f4d',
      coin: '#9897a9',
      fossil: '#787176',
    },
  },
  black: {
    sub: {
      black: '#000000',
      ebony: '#080401',
      crow: '#0d0906',
      charcoal: '#28231e',
      midnight: '#000004',
      ink: '#000000',
      raven: '#040301',
      oil: '#050100',
      grease: '#090806',
      onyx: '#040106',
      pitch: '#020001',
      soot: '#150f06',
      sable: '#030200',
      'jet black': '#000000',
      coal: '#0b0a08',
      metal: '#0d0d0a',
      obsidian: '#020403',
      jade: '#010302',
      spider: '#030200',
      leather: '#0b0704',
    },
  },
};

export const codeForSubcolor: { [key: string]: string } = {};
export const maincolorsForSubcolor: { [key: string]: string } = {};

for (const [maincolor, data] of Object.entries(colors)) {
  for (const [subcolor, code] of Object.entries(data.sub)) {
    maincolorsForSubcolor[subcolor] = maincolor;
    codeForSubcolor[subcolor] = code;
  }
}
